'use strict';

angular.module('enervexSalesappApp').controller('QuoteCtrl', function($scope, $stateParams, $state, $q, QuotesService, Quote, Design, Product, Account, Document, Job, User, _, FileUploader, Auth, Util, $uibModal, Property, Manufacturer, LineitemSource, Flash, SystemsManager) {
	$scope.jobLabel = Util.jobLabel;
	$scope._ = _;
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	$scope.quoteId = $stateParams.quoteId;
	$scope.multiselected = false;
	$scope.configs = Property.configs();
	$scope.mode = $stateParams.mode;
	$scope.me = Auth.getCurrentUser;
	// $scope.products = Product.query();
	$scope.productsPage = [];
	$scope.isAdmin = Auth.isAdmin;
	$scope.me = Auth.getCurrentUser;
	Manufacturer.query().$promise.then(function(result){
		$scope.manufacturers = result
		$scope.manufacturer = _.find(result, function(o){
			return o.isDefault
		})
	})
	$scope.ctx = {
		formChanged: false,
		showShippingRow: false,
		action: ''
	}
	$scope.actions = [{
		name: "Action...",
		value: ''
	},{
		name: 'Change Multiplier',
		value: 'changeMultiplier'
	}]
	$scope.hasSelectedItems = function() {
		var found = _.find($scope.selectedItems, function(selectedItem){
			return selectedItem.selected == true
		})
		return found ? true : false
	}
	$scope.$on("$destroy", function() {
		Flash.clear()
	})
	LineitemSource.query().$promise.then(function(result){
		$scope.lineitemSources = result
	})
	$scope.lineitemSourceName = function(code) {
		var result = _.find($scope.lineitemSources,function(o){
			return o.code == code
		})
		return result ? result.name : ""
	}

	var uploader = $scope.uploader = new FileUploader({});
	$scope.itemTypes = [
		{
			name: 'Existing Product',
			value: 'existing'
		},
		{
			name: 'Custom Product',
			value: 'custom'
		}
	]
	function computeGrandTotal() {
		Util.computeGrandTotal($scope.quote, $scope.configs.quote_extras_config)
	}
	function createNewItem() {
		return {
			itemType: "existing",
			origin:"quote",
			quantity: 1,
			customProduct: {
				name: "",
				price: 0,
				description: "",
				unit: "EA"
			}
		}
	}
	function checkCompatiable() {
		console.log("checkCompatiable")
		Flash.clear()
		var incompatiableDesigns = _.filter($scope.quote && $scope.quote.designs, function(designObj){
			var versionInfo = designObj.design.versionInfo
			if (!designObj.selected) {
				return false
			} else if (!versionInfo) {
				return true
			} else if (designObj.design.application.computeAs == "DRY" || designObj.design.application.computeAs == "BWH" || designObj.design.application.computeAs == "COM" ) {
				return !(versionInfo.bom >= Util.frameworkVersion && versionInfo.calc >= Util.frameworkVersion)
			} else {
				return false
			}
		})
		if (incompatiableDesigns.length > 0) {
			var message = "<strong>Error!</strong> Incompatable designs referenced in this quote, please re-compute and save them"
			_.each(incompatiableDesigns, function(designObj, index){
				message = message + '<div><a href="/accounts/'+ $scope.accountId + '/jobs/'+ $scope.jobId + '/designs/'+designObj.design._id + '/drawing">' + designObj.design.name + " v"+designObj.version +'</a></div>'
			})
			Flash.create('danger', message, 0, {}, true);
		}
		$scope.ctx.compatible = incompatiableDesigns.length == 0
		return incompatiableDesigns.length == 0
	}
	$scope.exportLineitems = function() {
		var url = "/api/accounts/" + $scope.accountId + "/jobs/" + $scope.jobId + "/quotes/" + $scope.quoteId +"/export-lineitems";
		window.location.href = url;
	}
	function setup(){
		$scope.autoCompleteOptions = {
			minimumChars: 1,
			dropdownWidth: "500px",
			data: function (term) {
				var params = {
					limit:20,
					term: term,
					manufacturer: $scope.manufacturer._id
				};

				if ($scope.configs.allow_deleted_manual_products != 'true'){
					params.deleted = false;
				}
				// params.isAggregate = 'false'
				return Product.query(params).$promise.then(function(products){
					$scope.productsPage = products
					return _.map(products, function(product){
						return product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)
					});
				})
			},
			itemSelected: function(item){
				var fullProduct = _.find($scope.productsPage, function(product){
					return (product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)) == item.item
				})
				$scope.newItem.product = fullProduct;
				fullProduct.formattedPrice = accounting.formatMoney(fullProduct.price);
			}
		}
		Job.get({
			id: $stateParams.jobId,
			accountId: $stateParams.accountId
		}).$promise.then(function(job){
			$scope.job = job;
			$scope.permissions = Auth.jobPermissions($scope.job, $scope.configs)
			$scope.isInternalMember = Auth.isInternalMember($scope.job);
			if ($stateParams.quoteId != 'new'){
				fetchQuote();
				fetchComments();
			} else {
				getDesigns();
				showShippingRow()
				$scope.editForm = true;
				$scope.selectedItems = [];
				$scope.quote = {
					visibility: Util.defaultVisibility($scope.job),
					notifyMembers: []
				};
				$scope.target = $scope.quote;
				Util.setNotifyMembersType($scope.quote, $scope.job)
			}
		});
		Account.get({
			id: $stateParams.accountId
		}).$promise.then(function(res){
			$scope.account = res;
		});

		if ($stateParams.mode == "edit") {
			$scope.mode = "edit"
		}
		if ($scope.mode === 'edit'){
			$scope.editForm = true;
		}
		$scope.checkedMembers = [];
		$scope.priceActive = false;
		$scope.checkedEmailMembers = [];
		$scope.setChecks = false;
		$scope.newComment = {
			notifyMembers:[]
		};
		Util.setNotifyMembersType($scope.newComment, $scope.job)
		$scope.newItem = createNewItem();
	}
	setup();
	$scope.editState = function(){
		$stateParams.mode = 'edit';
		$state.go('quote', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			quoteId: $scope.quoteId,
			mode: 'edit',
		}, {
			reload: true,
			inherit: false
		});
	}
	$scope.change = function() {
		$scope.ctx.formChanged = true
		showShippingRow()
	}
	$scope.multiSelectChange = function(multiSelect){
		_.each($scope.selectedItems, function(selectedItem){
			selectedItem.selected = multiSelect
		})
		$scope.multiselected = multiSelect
		$scope.checkSelected()
	}
	$scope.checkSelected = function() {
		var checked = _.find($scope.selectedItems, function(selectedItem){
			return selectedItem.selected
		})
		if (checked){
			$scope.multiselected = true
		} else {
			$scope.multiselected = false
			$scope.ctx.action = ''
		}
	}
	$scope.applyMultiplier = function(multiplier){
		_.each($scope.selectedItems, function(selectedItem){
			if (selectedItem.selected){
				selectedItem.factor = parseFloat(multiplier)
				$scope.updateFactor(selectedItem)
			}
		})
	}
	$scope.unremoveProduct = function(item){
		item.quoteRemoved = false;
		$scope.updatePricing();
	}
	$scope.removeProduct = function(item){
		$scope.ctx.formChanged = true;
		if (item.origin == "quote"){
			var index = $scope.selectedItems.indexOf(item);
			if (index > -1){
				$scope.selectedItems.splice(index, 1);
			}
		} else {
			item.quoteRemoved = true
		}
		$scope.updatePricing()
	}
	$scope.addProduct = function(item){
		$scope.ctx.formChanged = true;
		item.origin = 'quote'
		$scope.newItem = createNewItem();
		if (item.itemType == "existing") {
			item.list = item.product.price
			delete item.customProduct
		} else {
			item.list = item.customProduct.price
			delete item.product
		}
		item.price = item.list
		if (!item.price || item.price === null || item.price === 'undefined'){
			item.price = 0;
		}
		item.price = Number(accounting.toFixed(item.price, 2));
		if (item.list != 0){
			item.factor = Number(Math.round((item.price / item.list)+'e2')+'e-2');
		} else {
			item.factor = ''
		}
		$scope.selectedItems.push(item)
		$scope.updatePricing()
	}
	$scope.addCustomProduct = function(item){
		$scope.addProduct(item)
	}
	$scope.clearForm = function(){
		$scope.newItem = createNewItem();
	}
	$scope.previousVersion = function(version){
		var latestV = _.max($scope.quoteVersions, function(quote){
			return quote.version;
		});

		if (version != latestV) {
			$state.go('quote', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				quoteId: $scope.quoteId,
				version: version.version, 
				mode: 'version'
			}, {
				reload: true,
				inherit: false
			});
			// $scope.quote = version;
			// $scope.checkedMembers = version.explicitMembers;
			// getDesigns();
		} else {
			// $scope.mode = '';
			$state.go('quote', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				quoteId: $scope.quoteId,
			}, {
				relaod: true,
				inherit: false
			});
		}
	}
	function fetchQuote() {
		getQuoteVersions();
		$scope.selectedItems = [];

		QuotesService.getQuote().$promise.then(function(quote){
			$scope.quote = quote;
			_.each($scope.quote.designs, function(design){
				design.selected = true
			})
			$scope.target = $scope.quote;
			groupsToSelectedItems($scope.quote.groups)
			Util.setNotifyMembersType($scope.quote, $scope.job)
			$scope.checkedMembers = quote.explicitMembers;
			if ($stateParams.version != undefined) {
				var version = Number($stateParams.version);
				QuotesService.getQuoteVersions().$promise.then(function(res){
					$scope.quoteVersions = res;
				})
			}
			checkProposal()
		}).then(function(){
			getDesigns();
			showShippingRow()
		});
	}
	function getDesigns(){
		Design.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		}).$promise.then(function(res){
			var designs = _.map(res, function(design){
				var existing = _.find($scope.quote.designs, function(o){
					if (_.isString(o.design)) {
						return o.design == design._id
					} else {
						return o.design._id == design._id
					}
					
				})
				if (existing) {
					existing.design = design
					existing.selected = true
					existing.versionList = design.version
					return existing
				} else {
					var include = {}
					//default to all to account for manual items and computeAs == none
					_.each($scope.lineitemSources, function(lineitemSource) {
						include[lineitemSource.code] = true
					})
					return {
						design: design,
						selected: false,
						version: design.version,
						versionList: design.version,
						include: include
					}
				}
			})
			designs = _.sortBy(designs, function(design){
				return design.design.name && design.design.name.toLowerCase()
			})
			$scope.quote.designs = designs
			selectDesignFromQueryParams(function() {
				if ($scope.mode == "edit") {
					getLineItems();
				}
				showShippingRow()
				checkCompatiable()
			})
		});
	}
	function getQuoteVersions(){
		QuotesService.getQuoteVersions().$promise.then(function(res){
			$scope.quoteVersions = res;
		})
	}
	$scope.toggleCheck = Util.toggleCheck;
	$scope.memberOf = Util.memberOf;
	$scope.deleteQuote = function(quote){
		var parentElem = angular.element(document.querySelector('#quote-main'));
		$uibModal.open({
			templateUrl: 'app/main/shared/confirm.html',
			scope: $scope,
			appendTo: parentElem,
			controller: 'ConfirmCtrl',
			size: 'sm',
			resolve: {
				title: function() { 
					return "Delete Quote"
				},
				body: function() {
					return "Are you sure you want to delete the quote?"
				}
			}
		}).result.then(function(confirmed) {
			if (confirmed){
				QuotesService.deleteQuote(quote).$promise.then(function(res){
					$state.go('quotes', {
						accountId: $scope.accountId,
						jobId: $scope.jobId,
					}, {
						relaod: true,
						inherit: false
					});
				})
			} else {
				$scope.confirmDelete = false;
			}
		}).catch(function(){
			$scope.confirmDelete = false;
		});
	}	
	$scope.cancel = function(){
		if ($scope.quoteId == 'new'){
			$state.go('quotes', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
			}, {
				relaod: true,
				inherit: false
			});
		} else {
			$state.go('quote', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				quoteId: $scope.quoteId,
			}, {
				relaod: true,
				inherit: false
			});
		}
	}
	function getQuoteDesigns() {
		var selected = _.filter($scope.quote.designs, function(d){
			return d.selected;
		});
		return _.map(selected, function(d){
			return {
				design: d.design._id,
				version: d.version,
				include: d.include
			}
		})
	}
	function groupsToSelectedItems(groups){
		$scope.selectedItems = []
		$scope.totalQty = 0
		$scope.quote.totalPrice = $scope.quote.shippingCost || 0;
		_.each(groups, function(group){
			group.groupTitle = group.name;
			var groupRow = {
				groupTitle: group.name,
				groupRow: true,
				titleSet: true,
			};
			if(group.items.length > 0){
				$scope.selectedItems.push(groupRow);
			}
			_.each(group.items, function(item){
				if (!item.price || item.price === null || item.price === 'undefined'){
					item.price = 0;
				}
				item.price = Number(accounting.toFixed(item.price, 2));
				if (item.list != 0){
					item.factor = Number(Math.round((item.price / item.list)+'e2')+'e-2');
				} else {
					item.factor = ''
				}
				$scope.selectedItems.push(item)
				if (!(item.removed || item.quoteRemoved)){
					$scope.totalQty = $scope.totalQty + getQuantity(item)
					$scope.quote.totalPrice = $scope.quote.totalPrice + item.totalPrice
				}
			});
		});
		computeGrandTotal()
	}
	function getLineItems() {
		console.log("getLineItems")
		var designs = getQuoteDesigns()
		Quote.generateLineitems({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
		}, {
			designs: designs,
			groups: $scope.quote.groups
		}).$promise.then(function(res){
			groupsToSelectedItems(res.groups)
		});
	}
	function getQuantity(item) {
		if (item.removed || item.quoteRemoved) {
			item._quantity = 0
		} else {
			var result = (item.quoteQuantity !== undefined &&  item.quoteQuantity !== null && item.quoteQuantity !== "") ? item.quoteQuantity : item.quantity
			item._quantity = parseInt(result)
		}
		return item._quantity
	}
	$scope.updatePricing = function(){
		$scope.ctx.formChanged = true;
		$scope.quote.totalPrice = $scope.quote.shippingCost || 0;
		_.each($scope.selectedItems, function(item){
			if (!item.groupRow && !(item.removed || item.quoteRemoved)){
				try {
					var totalPrice = parseInt(getQuantity(item)) * item.price
					$scope.quote.totalPrice = $scope.quote.totalPrice + totalPrice
				}catch(e){
					console.log(e)
				}
			}
		})
		computeGrandTotal()
	}
	$scope.updatePrice = function(item){
		$scope.ctx.formChanged = true;
		if (item.list > 0){
			item.factor = item.price/item.list;
		} else {
			item.factor = 1;
		}
		item.totalPrice = item.price * getQuantity(item)
		$scope.updatePricing(item)
	}
	$scope.updateFactor = function(item){
		$scope.ctx.formChanged = true;
		item.price = Number(accounting.toFixed(item.factor * item.list,2));
		item.totalPrice = item.price * getQuantity(item)
		$scope.updatePricing(item)
	}
	$scope.priceChange = function(){
		if ($scope.priceActive === false){
			$scope.priceActive = true
		} else {
			$scope.priceActive = false;
		}
	}
	function selectDesignFromQueryParams(cb) {
		if ($stateParams.designId && $scope.mode == "edit"){
			var existing = _.find($scope.quote.designs, function(d) {
				return d.design._id == $stateParams.designId || d.design == $stateParams.designId
			})
			if (existing){
				existing.selected = true;
				$scope.ctx.formChanged = true
				existing.version = existing.versionList
				if ($stateParams.quoteId == "new"){
					$scope.quote.name = existing.design.name + " quote"
				}
			}
		}
		if (cb) {
			cb()
		}
	}
	$scope.viewProposal = function() {
		$state.go('proposal', {
			accountId: $scope.accountId,
			jobId:$scope.jobId, 
			documentId: $scope.existingDocument._id,
			mode:"edit"
		}, {
			reload: true,
			inherit: false
		});
	}
	$scope.createProposal = function() {
		$state.go('proposal', {
			accountId: $scope.accountId,
			jobId:$scope.jobId, 
			documentId: "new",
			quoteId: $scope.quote._id, 
			mode:"edit"
		}, {
			reload: true,
			inherit: false
		});	
	}
	$scope.updateProposal = function() {
		$state.go('proposal', {
			accountId: $scope.accountId,
			jobId:$scope.jobId, 
			documentId: $scope.existingDocument._id,
			quoteId: $scope.quote._id, 
			mode:"edit"
		}, {
			reload: true,
			inherit: false
		});
	}
	function checkProposal() {
		$scope.existingProposal = null
		$scope.canUpdateProposal = false;
		$scope.canCreateProposal = false;
		$scope.canViewProposal = false;
		if (!$scope.quote){
			return
		}
		Document.query({
			accountId: $scope.accountId,
			jobId: $scope.jobId
		}).$promise.then(function(documents){
			$scope.existingDocument = _.find(documents, function(document){
				return document.quote && document.quote.quote && document.quote.quote._id == $scope.quote._id
			})
			$scope.canCreateProposal = ($scope.existingDocument) ? false : true;
			$scope.canUpdateProposal = ($scope.existingDocument && $scope.existingDocument.quote.version != $scope.quote.version) ? true : false;
			$scope.canViewProposal = ($scope.existingDocument && $scope.existingDocument.quote.version == $scope.quote.version) ? true : false;
		})
	}

	$scope.updateDesignVersion = function(design){
		$scope.ctx.formChanged = true;
		getLineItems();
	}
	$scope.selectDesign = function(design){
		console.log("selectDesign")
		$scope.ctx.formChanged = true;
		getLineItems();
		showShippingRow()
		checkCompatiable()
	}
	$scope.addGroupRow = function(){
		$scope.ctx.formChanged = true;
		var groupRow = {
			groupTitle: '',
			groupRow: true,
			titleSet: false,
		};
		$scope.selectedItems.unshift(groupRow);
	}
	$scope.setGroupTitle = function(item){
		item.titleSet = true;
		if ($stateParams.quoteId === 'new'){
			if ($scope.newQuoteVar === true) {
				$scope.newQuoteVar = false;
			} else {
				$scope.newQuoteVar = true;
			}
		}
	}
	$scope.updateGroupRow = function(item){
		item.titleSet = false;
		$scope.ctx.formChanged = true;
		if ($stateParams.quoteId === 'new'){
			if ($scope.newQuoteVar === true) {
				$scope.newQuoteVar = false;
			} else {
				$scope.newQuoteVar = true;
			}
		}
	}
	function showShippingRow() {
		console.log("hidingShippingRow")
		// var designs = _.filter($scope.quote && $scope.quote.designs, function(o){
		// 	return o.selected
		// })
		// $scope.ctx.showShippingRow = Util.showShippingRow(designs)
	}
	$scope.saveQuoteVersion = function(){
		var groups = [];

		var currentGroup = null
		_.each($scope.selectedItems, function(_item){
			if (_item.groupRow === true){
				currentGroup = _.clone(_item)
				currentGroup.items = []
				currentGroup.name = _item.groupTitle
				groups.push(currentGroup)
			} else if (!_item.groupRow) {
				var item = _.clone(_item)
				if (item.product){
					item.product = item.product._id;
				}
				item.multiplier = item.factor
				currentGroup.items.push(item)
			}
		})

		if ($scope.quote.visibility === 'explicit'){
			$scope.quote.explicitMembers = $scope.checkedMembers;
		} else {
			$scope.quote.explicitMembers = [];
		}


		if ($scope.quote.notifyMembersType === 'all'){
			$scope.quote.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if ($scope.quote.notifyMembersType === 'some') {
			$scope.quote.notifyMembers = $scope.checkedEmailMembers;
		} else if ($scope.quote.notifyMembersType === 'none'){
			$scope.quote.notifyMembers = [];
		}
		var payload = _.clone($scope.quote)
		payload.groups = groups;
		payload.designs = getQuoteDesigns();

		if ($stateParams.quoteId === 'new') {
			QuotesService.newQuote(payload).$promise.then(function(res){
				$state.go('quote', {
					accountId: $stateParams.accountId,
					jobId: $stateParams.jobId,
					quoteId: res._id
				}, {
					reload:true,
					inherit: false
				})
			})
		} else {
			QuotesService.newQuoteVersion(payload).$promise.then(function(res){
				$state.go('quote', {
					accountId: $stateParams.accountId,
					jobId: $stateParams.jobId,
					quoteId: $scope.quoteId
				}, {
					reload: true,
					inherit: false
				});
			});
		}
	}
	$scope.toggleEmailCheck = function(member){
		$scope.ctx.formChanged = true;
		var memberId = member.user._id
		if ($scope.checkedEmailMembers.indexOf(memberId) === -1){
			$scope.checkedEmailMembers.push(member.user._id);
		} else {
			$scope.checkedEmailMembers.splice($scope.checkedEmailMembers.indexOf(memberId), 1);
		}
	}
	$scope.selectAllEmails = function(){
		$scope.checkedEmailMembers = [];
		_.each($scope.job.members, function(member){
			$scope.checkedEmailMembers.push(member.user._id);
		})
		$scope.setChecks = true;
	}
	$scope.deselectAllEmails = function(){
		$scope.checkedEmailMembers = [];
		$scope.setChecks = false;
	}
	function fetchComments(){
		QuotesService.getComments().$promise.then(function(quoteComments){
			$scope.quoteComments = quoteComments;
		});
	}
	$scope.submitComment = function(newComment) {
		if (newComment.notifyMembersType === 'all'){
			newComment.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if (newComment.notifyMembersType === 'some' ) {
			newComment.notifyMembers = $scope.checkedEmailMembers;
		} else if (newComment.notifyMembersType === 'none'){
			newComment.notifyMembers = [];
		}
		if ($scope.uploader.queue.length > 0) {
			var promises = [];
			_.each($scope.uploader.queue, function(data){
				var fd = new FormData();
				fd.append('name', data._file.name);
				fd.append('asset', data._file);
				fd.append('visibility', $scope.quote.visibility);
				promises.push(QuotesService.createAttachment(fd).$promise);
			});
			$q.all(promises).then(function(responses){
				newComment.attachments = responses;
				QuotesService.createComment(newComment).$promise.then(function(res) {
					$scope.newComment = {
						notifyMembers:[]
					};
					Util.setNotifyMembersType($scope.newComment, $scope.job)
					$scope.uploader.queue = [];
					fetchComments();
				});
			})
		} else {
			QuotesService.createComment(newComment).$promise.then(function(res) {
				$scope.uploader.queue = [];
				$scope.newComment = {
					notifyMembers:[]
				};
				Util.setNotifyMembersType($scope.newComment, $scope.job)
				fetchComments();
			});
		}
	}
	$scope.deleteComment = function(comment) {
		QuotesService.removeComment(comment).$promise.then(function(res){
			$scope.attachments = [];
			fetchComments();
		})
	}
	$scope.saveCommentChange = function(comment){
		QuotesService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
	$scope.deleteAttachment = function(comment, attachment){
		comment.attachments = _.without(comment.attachments, attachment);
		QuotesService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
	$scope.systemName = function(systemId, design) {
		return SystemsManager.systemName(systemId, design)
	}
	$scope.bomLink = function(rule) {
		return "B-"+rule.id.substring(rule.id.length -5)
	}
	$scope.stackLink = function(rule) {
		return "S-"+rule.id.substring(rule.id.length -5)
	}


});
